@use "./_mantine.scss" as *;
.icon {
    z-index: 102;

    transition:
        right 200ms,
        transorm 200ms;
}

.questionButton {
    font-weight: 600;
    text-align: left;

    &:focus {
        outline: none;
    }
}

.aiModalContainer {
    :global {
        .mantine-Modal-body {
            padding: 0;
        }
    }
}

.suggestion {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    border: 1px solid var(--mantine-color-brand-1);
    background-color: var(--mantine-color-brand-light);

    padding: 8px;
    border-radius: var(--mantine-radius-sm);

    color: var(--mantine-color-brand-light-color);
    font-size: var(--mantine-font-size-xs);

    svg {
        flex-shrink: 0;
        color: var(--mantine-color-brand-2);
    }

    &:hover {
        border-color: var(--mantine-color-brand-2);

        svg {
            color: var(--mantine-color-brand-3);
        }
    }

    &:disabled {
        pointer-events: none;
        opacity: 0.5;
    }
}
