@use "./_mantine.scss" as *;
.file {
    pointer-events: all;
    overflow: hidden;

    svg {
        width: 18px;
        height: 18px;

        stroke-width: 1.25px;

        margin-top: 1px;
    }

    a {
        font-size: 12px;
        text-decoration: none;

        color: var(--mantine-color-gray-8);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover,
        &:focus {
            color: var(--mantine-color-blue-7);
        }
    }
}
