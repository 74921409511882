@use "./_mantine.scss" as *;
.imageContainer {
    position: relative;

    aspect-ratio: 1;

    padding: var(--mantine-radius-sm);

    border-radius: var(--mantine-radius-sm);
    border: 1px solid var(--mantine-color-gray-2);
}

.image {
    width: 100%;
    height: 100%;

    object-fit: contain;

    cursor: move;
}
