@use "./_mantine.scss" as *;
.root {
    font-size: inherit;
    font-weight: 600;
    color: var(--mantine-color-primary-6);

    text-decoration: underline;
    text-decoration-style: dashed;
    text-decoration-color: var(--mantine-color-primary-3);
    text-underline-offset: 3px;

    svg {
        margin-right: 3px;

        transform: translateY(2px);
    }
}
