@use "./_mantine.scss" as *;
.textareaWrapper {
    background-color: var(--textarea-background-color, transparent);
    border-color: var(--textarea-border-color, transparent);

    border-style: var(--textarea-border-style, none);
    border-radius: 0;
}

.textarea {
    color: var(--textarea-color);

    * {
        font-size: var(--textarea-font-size);
    }

    h1,
    h1 * {
        font-size: calc(var(--textarea-font-size) * 2);
    }

    h2,
    h2 * {
        font-size: calc(var(--textarea-font-size) * 1.5);
    }

    :global .mantine-RichTextEditor-root,
    :global .mantine-RichTextEditor-content,
    :global .tiptap {
        background-color: transparent;
        border-color: transparent;
    }

    :global .tiptap {
        padding: 4px;
    }

    :global [data-type='taskList'] {
        label {
            margin-right: var(--mantine-spacing-xs);
        }

        input {
            margin: 0;
        }
    }

    :global [data-checked='true'] {
        // make sure to apply line-through to the deepest element
        *:not(:has(*)) {
            text-decoration: line-through;
        }
    }
}
