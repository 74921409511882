@use "./_mantine.scss" as *;
.linkedInfo {
    padding-left: 1px;

    font-size: var(--mantine-font-size-xs);
    line-height: 24px; // align with inputs
}

.productImage {
    cursor: zoom-in;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 4px;
    border-radius: var(--mantine-radius-xs);
    border: 1px solid var(--mantine-color-gray-2);
}
