@use "./_mantine.scss" as *;
.wrapper {
    position: absolute;
}

.shadow {
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;

    border: none;
    border-radius: var(--mantine-radius-sm);

    cursor: pointer;

    &[data-variant='default'] {
        background: var(--mantine-color-gray-2);
    }

    &[data-variant='warning'] {
        background: var(--mantine-color-red-2);
    }

    &[data-animation='pulsing'] {
        @keyframes pulse {
            from {
                opacity: 100%;
            }
            to {
                opacity: 40%;
            }
        }

        animation: pulse 1s infinite alternate-reverse;

        &:hover {
            animation: none;
        }
    }

    [data-resizing='true'] & {
        background: none;
    }
}
