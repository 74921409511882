@use "./_mantine.scss" as *;
.button {
    display: flex;
    gap: var(--mantine-spacing-sm);

    width: 100%;
    padding: var(--mantine-spacing-sm);

    border: 1px solid var(--mantine-color-gray-3);
    border-radius: var(--mantine-radius-sm);

    background-color: var(--mantine-color-white);

    &:hover,
    &:focus {
        background-color: var(--mantine-color-gray-0);
    }
}

.buttonRadio {
    --radio-size: 18px;
}

.buttonTitle {
    font-size: 13px;
    font-weight: 600;

    line-height: 18px;
}

.buttonDescription {
    font-size: 11px;
    color: var(--mantine-color-gray-6);
}
