@use "./_mantine.scss" as *;
.toolbar {
    & > * {
        border-radius: 4px;
        // outline: 1px solid var(--mantine-color-gray-6);
    }

    border-radius: 2px;
    outline: 1px solid var(--mantine-color-gray-3);

    :global {
        .mantine-Button-root {
            position: relative;

            width: 32px;
            height: 32px;

            padding: 0;

            background-color: #ffffff;
            color: var(--mantine-color-gray-7);

            svg {
                width: 15px;
                height: 15px;
            }

            &:not([disabled]):has(:hover),
            &[data-active='true'] {
                background-color: var(--mantine-color-gray-1);
                color: var(--mantine-color-gray-9);

                transform: none;
            }

            &[disabled] {
                background-color: var(--mantine-color-default);
                color: var(--mantine-color-gray-5);
            }

            &[data-indicator='true'] {
                &::after {
                    content: '';

                    position: absolute;
                    top: 8px;
                    right: 8px;

                    display: block;
                    width: 6px;
                    height: 6px;

                    border-radius: 50%;

                    background-color: var(--mantine-color-red-6);
                }
            }
        }
    }
}
