@use "./_mantine.scss" as *;
.sidebar {
    flex-grow: 0;
    flex-shrink: 0;

    position: relative;

    height: 100dvh;

    box-shadow: var(--mantine-shadow-xs);
    background-color: white;

    transition: width 300ms;

    :global .mantine-ScrollArea-viewport > div {
        display: flex !important;
        flex-direction: column;

        height: 100%;
    }

    // react-resizable css
    pointer-events: all;

    &[data-resizing='true'] {
        pointer-events: none;
        transition: none;
    }

    @media (pointer: coarse) {
        user-select: none;
        -webkit-user-select: none;
    }
}

.sidebarErrorBoundary {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--mantine-spacing-lg);
    padding: var(--mantine-spacing-lg);

    text-align: center;

    svg {
        width: 36px;
        height: 36px;
    }
}

.resizeHandle {
    cursor: col-resize;

    position: absolute;
    top: 0;
    bottom: 0;
    left: -10px;

    width: 20px;

    &::after {
        content: '';
        position: absolute;
        left: 6px;
        top: 0;
        bottom: 0;

        width: 4px;
    }

    &:hover,
    &[data-resizing='true'] {
        &::after {
            background-color: var(--mantine-color-gray-3);
        }
    }
}

.sidebarScrollArea {
    scrollbar-width: thin;

    overflow-y: auto;

    @media (pointer: coarse) {
        [data-dragging='true'] & {
            overflow-y: hidden;
        }
    }
}
