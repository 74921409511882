@use "./_mantine.scss" as *;
.emailWithPermission {
    display: flex;
    align-items: center;

    > *:nth-child(1) input {
        flex-grow: 1;
        flex-shrink: 1;

        border-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    > *:nth-child(2) input {
        flex-grow: 0;
        flex-shrink: 0;

        border-left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    &:focus-within > *:nth-child(1) input,
    &:focus-within > *:nth-child(2) input {
        border-color: var(--mantine-color-gray-4);
    }
}
