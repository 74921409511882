@use "./_mantine.scss" as *;
.cursor {
    position: absolute;

    width: 1rem;
    height: 1rem;

    z-index: 997;

    pointer-events: none;

    transition: all 0.5s ease;
}

.icon {
    color: var(--cursor-color);

    stroke: currentColor;
    stroke-width: 1px;
    stroke-linecap: round;

    transform: rotate(270deg);
}

.name {
    position: absolute;
    top: 0;
    left: 0;

    width: max-content;
    max-width: 8rem;

    padding: 0.2rem 0.2rem;

    font-size: var(--mantine-font-size-xs);
    font-weight: 600;

    line-height: var(--mantine-font-size-xs);

    background-color: var(--cursor-color);
    color: var(--mantine-color-white);

    transform: translate(0.75rem, 0.75rem);
}
