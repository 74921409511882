@use "./_mantine.scss" as *;
.empty {
    width: 80%;
    margin: var(--mantine-spacing-lg) auto;

    text-align: center;
    text-wrap: balance;
}

.channelList {
}

.channelListActions {
    margin-top: var(--mantine-spacing-xs);
}

.channelTeaser {
    display: flex;

    justify-content: space-between;
    align-items: center;
}

.channelTeaserName {
    display: flex;

    align-items: center;
    gap: calc(var(--mantine-spacing-xs) / 2);

    &:hover {
        text-decoration: underline;
    }

    svg {
        color: var(--mantine-color-gray-5);
    }
}

.threadHeader {
    display: flex;
    align-items: center;

    gap: var(--mantine-spacing-xs);

    margin-top: -6px;
    margin-bottom: var(--mantine-spacing-sm);

    font-size: 14px;
    font-weight: 600;
}

.message {
    position: relative;

    padding: var(--mantine-spacing-xs);
    gap: 4px;

    background-color: var(--mantine-color-gray-0);
    border-radius: var(--mantine-radius-xs);
}

.messageActions {
    opacity: 0;

    transition: opacity 0.3s ease;

    .message:hover & {
        opacity: 1;
    }
}

.messageThreadHint {
    position: absolute;
    top: 0;
    right: var(--mantine-spacing-md);
    bottom: 0;

    z-index: 2;

    display: flex;

    justify-content: center;
    align-items: center;

    width: 16px;

    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    .message:hover & {
        opacity: 1;
    }
}

.messageSidebar {
    flex-grow: 0;
    flex-shrink: 0;

    width: var(--message-avatar-width);
}

.messageContent {
    flex-grow: 0;
    flex-shrink: 0;

    width: calc(100% - var(--message-gap) - var(--message-avatar-width));
}

.messageReplyCount {
    font-weight: 500;

    color: var(--mantine-color-primary-7);
}
