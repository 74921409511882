@use "./_mantine.scss" as *;
.image {
    position: absolute;
    z-index: var(--image-z-index);

    left: var(--image-left);
    top: var(--image-top);

    overflow: hidden;

    border-radius: var(--mantine-radius-sm);
    outline-offset: 2px;

    &[data-selectable-preview='true'],
    &[data-selected='true'] {
        outline: 2px solid var(--mantine-color-primary-6);
    }
}
