@use "./_mantine.scss" as *;
.teaser {
    position: relative;

    cursor: pointer;

    transition: background-color 0.3s ease;

    &[data-indicator='true']::after {
        content: '';

        position: absolute;
        top: 22px;
        left: 24px;

        display: block;
        width: 8px;
        height: 8px;

        border-radius: 50%;

        background-color: var(--mantine-color-red-6);
    }

    &[data-active='true'] {
        background-color: var(--mantine-color-gray-0);
    }
}

.teaserMessagePreviews {
    display: grid;
    gap: calc(var(--mantine-spacing-xs) / 2);

    margin-top: calc(var(--mantine-spacing-xs));
    margin-left: 28px;
}

.teaserMessagePreview {
    display: flex;

    width: 100%;
    height: 14px;
    gap: 4px;

    overflow: hidden;

    p {
        flex-grow: 1;
        flex-shrink: 1;

        font-size: 10px;
        line-height: 14px;

        white-space: nowrap !important;
    }

    p + * {
        display: none;
    }

    svg {
        flex-grow: 0;
        flex-shrink: 0;

        margin: 1px;

        width: 12px;
        height: 12px;
    }
}

.teaserMessagePreviewUser {
    font-size: 10px;
    font-weight: 500;

    line-height: 14px;
}

.teaserFooter {
    display: flex;

    align-items: center;
    gap: var(--mantine-spacing-xs);

    margin-top: calc(var(--mantine-spacing-xs));
    margin-left: 28px;

    font-size: 12px;
    font-weight: 500;

    .teaserMessagePreviews + & {
        margin-top: calc(var(--mantine-spacing-xs) / 2);
    }
}

.teaserFooterReplies {
    display: flex;

    align-items: center;
    gap: 4px;

    font-size: 10px;
    line-height: 1;
    font-weight: 500;

    svg {
        width: 14px;
        height: 14px;
    }
}

.list {
    .teaser {
        padding: 24px 10px;

        border-top: 1px solid var(--mantine-color-gray-1);
        &:last-child {
            border-bottom: 1px solid var(--mantine-color-gray-1);
        }
    }
}

.listNoResults {
    display: flex;

    justify-content: center;
    align-items: center;

    padding: var(--mantine-spacing-xl);

    font-size: 12px;
    text-align: center;
}

.wrapper {
    position: absolute;

    pointer-events: all;
    cursor: default;
}

.target {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 40px;
    height: 40px;

    padding: 6px;

    background-color: white;

    box-shadow: var(--mantine-shadow-sm);
    border: 1px solid var(--mantine-color-gray-3);
    border-radius: 9999px 9999px 9999px 0;

    outline: none !important;

    transition:
        background-color 0.15s ease-in-out,
        border 0.15s ease-in-out,
        opacity 0.15s ease-in-out;

    &[data-resolved='true'] {
        opacity: 0.5;
    }

    &[data-selectable-preview='true'],
    &[data-selected='true'] {
        outline-offset: 2px;
        outline: 2px solid var(--mantine-color-primary-6) !important;
    }

    &[data-preview='true'] {
        @keyframes pulse {
            0% {
                transform: scale(1);
            }
            50% {
                transform: scale(1.1);
            }
            100% {
                transform: scale(1);
            }
        }

        background-color: var(--mantine-color-gray-8);
        border-color: var(--mantine-color-gray-8);

        animation: pulse 1s infinite;
    }
}

.popover {
    padding: var(--mantine-spacing-xs);

    background-color: #ffffff;

    border: 1px solid var(--mantine-color-gray-3);
    border-radius: var(--mantine-radius-sm);
    border-top-left-radius: var(--mantine-radius-lg);

    box-shadow: var(--mantine-shadow-sm);

    user-select: text;
}

.thread {
    position: relative;

    display: flex;
    align-items: flex-start;

    gap: 8px;
}

.threadAvatar {
    position: sticky;
    top: 0;

    display: flex;
    align-items: center;

    width: 20px;
    height: 20px;
}

.threadContent {
    width: calc(100% - 28px - 8px); // avatar + gap + padding
}

.threadItemHeader {
    position: relative;

    display: flex;
    flex-wrap: wrap;

    align-items: center;

    gap: 0 6px;

    min-height: 20px;

    font-size: 12px;
    font-weight: 600;

    small {
        font-size: 12px;
        font-weight: 400;

        color: var(--mantine-color-gray-5);
    }
}

.threadItemHeaderLabel {
    display: flex;
    align-items: center;

    margin-left: 8px;
    padding: 2px 4px;

    font-size: 10px;
    font-weight: 600;
    line-height: 1;

    background-color: var(--mantine-color-primary-1);
    color: var(--mantine-color-primary-7);

    border-radius: 2px;
}

.threadItemBody {
    flex-grow: 1;
    flex-shrink: 1;

    font-size: 12px;

    [data-comment-teaser='true'] & :global .tiptap {
        > * {
            white-space: nowrap;

            overflow: hidden;
            text-overflow: ellipsis;
        }

        > * + * {
            display: none;
        }
    }

    :global [data-type='mention'] {
        font-weight: 500;
        color: var(--mantine-color-primary-6);
    }
}

.threadItemBodyReadMore {
    font-size: 12px;
    color: var(--mantine-color-primary-7);
}

.threadNotice {
    margin-left: 20px + 8px;
    padding: var(--mantine-spacing-xs);

    font-size: 11px;

    background: var(--mantine-color-gray-0);

    border: 1px solid var(--mantine-color-gray-1);
    border-radius: var(--mantine-radius-xs);

    p {
        margin: 0;
    }

    p + p {
        margin-top: 0.5em;
    }

    button {
        font-size: inherit;
        color: var(--mantine-color-primary-6);

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    .teaser & {
        display: none;
    }
}

.messageComposer {
    width: 100%;
}

.messageComposerContent {
    --mantine-color-placeholder: var(--mantine-color-gray-5);

    :global .tiptap {
        padding: 8px 8px 4px 8px;
        font-size: 12px;

        [data-type='mention'] {
            font-weight: 500;
            color: var(--mantine-color-primary-6);
        }
    }
}

.messageComposerActions {
    display: flex;
    justify-content: space-between;

    padding: 4px;
    padding-top: 0;
}

.messageComposerActionsSection {
    display: flex;
}

.messageComposerAction {
    display: flex;

    justify-content: center;
    align-items: center;

    width: 24px;
    height: 24px;

    background-color: transparent;
    color: var(--mantine-color-gray-7);

    border: none;
    border-radius: var(--mantine-radius-sm);

    &:not([disabled]):has(:hover, :focus) {
        background-color: var(--mantine-color-gray-1);
    }

    &:focus {
        background-color: transparent;
        outline: none;
    }

    &[disabled] {
        color: var(--mantine-color-gray-5);
    }

    svg {
        width: 16px;
        height: 16px;
    }
}

.messageComposerMentionTarget {
    width: 100%;
    transform: translateY(4px);
}

.tips {
    display: flex;
    align-items: center;

    gap: 4px;

    margin-top: 10px;

    font-size: 11px;
    font-weight: 500;

    line-height: 1;

    color: var(--mantine-color-gray-7);

    &:hover,
    &:focus {
        color: var(--mantine-color-gray-9);

        svg {
            color: var(--mantine-color-primary-7);
        }
    }

    svg {
        width: 13px;
        height: 13px;

        color: var(--mantine-color-gray-9);
        transform: scaleX(-1); // flip wand icon.
    }
}

.mentionDropdownOption {
    display: flex;
    align-items: center;

    gap: 10px;

    line-height: 1;
}

.filesPreview {
    display: flex;

    gap: calc(var(--mantine-spacing-xs) / 2);

    .thread & {
        margin-top: var(--mantine-spacing-xs);
    }

    .messageComposer & {
        padding: 4px 8px;
    }
}

.filePreview {
    display: flex;

    justify-content: center;
    align-items: center;

    width: 60px;
    height: 60px;

    color: var(--mantine-color-gray-7);
    background-color: var(--mantine-color-gray-0);
    border: 1px solid var(--mantine-color-gray-1);
    border-radius: var(--mantine-radius-sm);

    img {
        width: 100%;
        height: 100%;

        object-fit: cover;

        border-radius: var(--mantine-radius-sm);
    }

    svg {
        width: 30%;
        height: auto;
    }

    &:has(img) {
        background-color: transparent;
        border: none;
    }
}
