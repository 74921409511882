@use "./_mantine.scss" as *;
.wrapper {
    height: 100%;
}

.overlay {
    position: absolute;
    left: 0;
    top: 0;

    z-index: 89;

    width: 100%;
    height: 100%;

    pointer-events: none;

    transition: background-color 0.15s ease;

    &[data-active='true'] {
        background-color: rgba(0, 0, 0, 0.2);
    }
}
