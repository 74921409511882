@use "./_mantine.scss" as *;
.container {
    position: relative;

    width: 100%;
    height: 100%;
    overflow: hidden;

    user-select: none;
    touch-action: none;

    cursor: default;

    &[data-mode='edit'] {
        cursor: default;
    }

    &[data-mode='navigate'] {
        cursor: var(--navigate-cursor);
    }

    &[data-mode='comment'] {
        cursor: var(--comment-cursor);
    }

    &[data-mode='add-group'] {
        cursor: default;
    }

    &[data-mode='add-textarea'] {
        cursor: text;
    }
}
