@use "./_mantine.scss" as *;
.toolbar {
    --inline-toolbar-height: 36px;
    --inline-toolbar-spacer-width: 4px;

    --inline-toolbar-background-color: var(--mantine-color-gray-9);
    --inline-toolbar-color: rgba(255, 255, 255, 1);
    --inline-toolbar-color-inactive: rgba(255, 255, 255, 0.5);
    --inline-toolbar-color-disabled: rgba(255, 255, 255, 0.35);

    --inline-toolbar-divider-color: var(--mantine-color-gray-8);

    display: flex;

    width: max-content !important;
    padding: 0;

    font-weight: 500;

    color: var(--inline-toolbar-color);
    border: none;
    border-radius: var(--mantine-radius-sm);

    [data-dragging='true'] &,
    [data-panning='true'] & {
        opacity: 0 !important;
        pointer-events: none !important;
    }

    [data-mantine-color-scheme='light'] &,
    [data-mantine-color-scheme='dark'] & {
        --_popover-bd: var(--inline-toolbar-background-color) !important;
        --_popover-bg: var(--inline-toolbar-background-color) !important;

        background-color: var(--inline-toolbar-background-color);
    }

    > form {
        display: flex;
    }

    > *:first-child,
    > form > *:first-child {
        border-top-left-radius: var(--mantine-radius-sm);
        border-bottom-left-radius: var(--mantine-radius-sm);
    }

    > *:last-child,
    > form > *:last-child {
        border-top-right-radius: var(--mantine-radius-sm);
        border-bottom-right-radius: var(--mantine-radius-sm);
    }
}

.dropdown {
    display: flex;
    flex-direction: column;

    width: max-content !important;
    padding: 0;

    border: none;

    [data-mantine-color-scheme='light'] &,
    [data-mantine-color-scheme='dark'] & {
        --_popover-bd: var(--inline-toolbar-background-color);
        --_popover-bg: var(--inline-toolbar-background-color);

        background-color: var(--inline-toolbar-background-color);
    }

    // Dirty but it fixes it for now.
    [data-voltage-suggestions-dropdown] {
        color: var(--mantine-color-gray-7) !important;
    }
}

.fixedToolbar {
    position: relative;

    [data-dragging='true'] &,
    [data-panning='true'] & {
        opacity: 1 !important;
        pointer-events: all !important;
    }
}

.dropdownOptions {
    &[data-horizontal] {
        display: flex;
        flex-direction: row;
    }
}

.dropdownOption {
    display: flex;
    flex-direction: row;

    align-items: center;
    gap: var(--mantine-spacing-xs);

    min-height: var(--inline-toolbar-height);
    padding: var(--mantine-spacing-xs);

    background-color: transparent;
    color: var(--inline-toolbar-color-inactive);

    &:not([data-combobox-disabled]):hover,
    &:not([data-combobox-disabled]):focus {
        background-color: var(--mantine-color-gray-8);
        color: var(--inline-toolbar-color);
    }

    &[data-combobox-disabled] {
        color: var(--inline-toolbar-color-disabled);
    }

    &[data-combobox-active],
    &[data-combobox-active] svg {
        color: var(--inline-toolbar-color);
    }

    [data-horizontal] & {
        justify-content: center;
        align-items: center;

        width: var(--inline-toolbar-height);
        padding: 0;
    }

    svg {
        width: 16px;
        height: 16px;

        color: var(--inline-toolbar-color-disabled);

        stroke-width: 1.5;
    }
}

.dropdownSection {
    padding: var(--mantine-spacing-xs);
}

.target {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    pointer-events: none;
}

.divider {
    position: relative;
    z-index: 2;

    width: 1px;
    height: var(--inline-toolbar-height);

    background-color: var(--inline-toolbar-divider-color);
}

.spacer {
    width: var(--inline-toolbar-spacer-width);
    height: var(--inline-toolbar-height);
}

.tooltip {
    display: flex;
    align-items: center;
    gap: 10px;

    font-size: 12px;
    line-height: 16px;

    border-radius: 0;
}

.tooltipShortcut {
    display: flex;
    align-items: center;

    gap: 2px;

    color: var(--mantine-color-gray-5);

    svg {
        width: 14px;
        height: 14px;

        stroke-width: 1.5;
    }
}

.text {
    display: flex;
    align-items: center;

    height: var(--inline-toolbar-height);
    padding: var(--mantine-spacing-sm);

    font-size: 12px;
    font-weight: 500;

    &[data-dimmed='true'] {
        color: var(--inline-toolbar-color-inactive);
    }

    & + .divider {
        margin-left: 0;
    }

    .divider + & {
        margin-left: -4px;
    }
}

.menuItem {
    position: relative;
    z-index: 2; // this makes the menu item go over the popover arrow

    display: flex;
    align-items: center;

    gap: calc(var(--mantine-spacing-xs) / 2);

    width: 100%;
    height: 32px;

    min-width: 140px;

    padding-left: var(--mantine-spacing-xs);
    padding-right: var(--mantine-spacing-xs);

    font-size: 12px;
    font-weight: 500;

    background-color: transparent;
    color: var(--inline-toolbar-color);
    border: none;

    &:hover,
    &:focus {
        background-color: var(--mantine-color-gray-8);
        color: var(--inline-toolbar-color);
    }

    &:first-child {
        border-top-left-radius: var(--mantine-radius-sm);
        border-top-right-radius: var(--mantine-radius-sm);
    }

    &:last-child {
        border-bottom-left-radius: var(--mantine-radius-sm);
        border-bottom-right-radius: var(--mantine-radius-sm);
    }

    &[disabled] {
        color: var(--inline-toolbar-color-inactive);
        pointer-events: none;
    }
}

.menuItemIcon {
    width: 14px;
    height: 14px;

    svg {
        width: 14px;
        height: 14px;

        stroke-width: 1.5px;
    }
}

.autoInput {
    position: relative;

    .autoInputInput,
    .autoInputSizer {
        width: auto;

        font: inherit;

        padding: 0;
        margin: 0;

        border: none;
    }

    .autoInputInput {
        position: absolute;
        left: 0;
        top: 0;

        width: 100%;
        height: 100%;

        background: transparent;
        color: #ffffff;

        &::placeholder {
            color: var(--inline-toolbar-color-disabled);
        }

        &:focus {
            outline: none;
        }
    }

    .autoInputSizer {
        visibility: hidden;
        white-space: pre-wrap;
    }
}

.iconButton {
    position: relative;
    z-index: 2;

    display: flex;

    justify-content: center;
    align-items: center;

    width: var(--inline-toolbar-height);
    height: var(--inline-toolbar-height);

    color: var(--inline-toolbar-color-inactive);

    svg {
        width: 16px;
        height: auto;

        stroke-width: 1.5;
    }

    &:not([disabled]):hover,
    &:not([disabled]):focus {
        background-color: var(--mantine-color-gray-8);
        color: var(--inline-toolbar-color);

        outline: none;
    }

    &[disabled] {
        color: var(--inline-toolbar-color-disabled);
    }

    &[data-active='true'] {
        color: var(--inline-toolbar-color);
    }
}

.textButton {
    position: relative;
    z-index: 2;

    display: flex;

    justify-content: center;
    align-items: center;

    height: var(--inline-toolbar-height);
    padding: 0 calc(var(--mantine-spacing-sm) - var(--inline-toolbar-spacer-width));

    font-size: 12px;
    font-weight: 500;

    color: var(--inline-toolbar-color-inactive);

    &:not([disabled]):hover,
    &:not([disabled]):focus {
        background-color: var(--mantine-color-gray-8);
        color: var(--inline-toolbar-color);

        outline: none;
    }

    &[disabled] {
        color: var(--inline-toolbar-color-disabled);
    }

    &[data-active='true'] {
        color: var(--inline-toolbar-color);
    }
}

.measurement {
    width: calc(80px * var(--measurement-fields));

    :global .mantine-Grid-root {
        --grid-gutter: 0;
    }

    :global .mantine-Grid-col {
        &:not(:last-of-type) {
            border-right: 1px solid var(--mantine-color-gray-8);
        }
    }

    :global .mantine-Input-input {
        padding-left: 12px;

        font-size: 12px;

        background: transparent;
        color: var(--inline-toolbar-color);

        border: none;

        &::placeholder {
            color: var(--inline-toolbar-color-disabled);
        }
    }

    :global .mantine-Input-section {
        font-size: 12px;

        color: var(--inline-toolbar-color-disabled);
    }
}

.toolbar {
    :global .mantine-RichTextEditor-control {
        position: relative;
        z-index: 2;

        display: flex;

        justify-content: center;
        align-items: center;

        width: var(--inline-toolbar-height);
        height: var(--inline-toolbar-height);

        background-color: transparent;
        color: var(--inline-toolbar-color-inactive);

        border: none;

        svg {
            width: 16px;
            height: auto;

            stroke-width: 1.5;
        }

        &:not([disabled]):hover,
        &:not([disabled]):focus {
            background-color: var(--mantine-color-gray-8);
            color: var(--inline-toolbar-color);

            outline: none;
        }

        &[disabled] {
            color: var(--inline-toolbar-color-disabled);
        }

        &[data-active='true'] {
            color: var(--inline-toolbar-color);
        }
    }
}

.colorSwatch {
    cursor: pointer;

    position: relative;
    z-index: 2;

    display: flex;

    justify-content: center;
    align-items: center;

    width: var(--inline-toolbar-height);
    height: var(--inline-toolbar-height);

    :global .mantine-ColorSwatch-root {
        border: 1px solid var(--mantine-color-gray-7);
    }

    &:not([disabled]):hover,
    &:not([disabled]):focus {
        background-color: var(--mantine-color-gray-8);
        color: var(--inline-toolbar-color);

        outline: none;
    }
}

.colorPicker {
    :global .mantine-ColorPicker-saturation,
    :global .mantine-ColorPicker-saturationOverlay {
        border-radius: 0;
    }

    :global .mantine-ColorSwatch-shadowOverlay {
        display: none;
    }

    :global .mantine-ColorSwatch-root,
    :global .mantine-ColorSwatch-alphaOverlay,
    :global .mantine-ColorSwatch-colorOverlay {
        border-radius: 2px;
    }

    :global .mantine-ColorPicker-swatches {
        margin: 4px;
    }

    :global .mantine-ColorPicker-body {
        margin: 6px;
    }
}
