@use "./_mantine.scss" as *;
.label {
    position: absolute;
    left: var(--left);
    top: var(--top);

    width: var(--width);

    color: var(--text-color);
    text-align: var(--text-align);

    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
    white-space: nowrap;

    overflow: hidden;

    .innerLabel {
        position: relative;

        display: inline-block;

        z-index: var(--z-index);

        &:hover {
            z-index: var(--z-index-hover);
            background-color: var(--mantine-color-white);
        }
    }
}

.tooltip {
    padding: 2px 4px;
    border-radius: 0;

    background-color: transparent;
    background-color: var(--mantine-color-white);
    box-shadow: var(--mantine-shadow-md);
    border: 1px solid var(--mantine-color-gray-1);

    color: var(--mantine-color-gray-7);
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;

    .tooltipLabel {
        color: var(--text-color);
    }
}
