@use "./_mantine.scss" as *;
.filesNoComponentDescription {
    font-size: 12px;
}

.filesNoComponentActions {
    display: flex;
    gap: calc(var(--mantine-spacing-xs) / 2);

    margin-top: var(--mantine-radius-md);
}

.files {
    display: grid;
    gap: var(--mantine-spacing-xs);

    margin-top: var(--mantine-spacing-md);
}

.filesEmpty {
    font-size: 12px;

    color: var(--mantine-color-gray-8);
}

.filesActions {
    margin-top: var(--mantine-spacing-md);
}
