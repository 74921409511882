@use "./_mantine.scss" as *;
.tipsPagination {
    font-size: 11px;
    font-weight: 500;
    color: var(--mantine-color-gray-6);
}

.tipsNavigation {
    display: flex;
    align-items: center;

    svg {
        width: 12px;
        height: 12px;

        stroke-width: 3px;
    }
}
