@use "./_mantine.scss" as *;
.button {
    background-color: white;
}

.formTarget {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 1px;
    height: 1px;
}

.form {
    &[data-position='top'] {
        transform: translateY(50%);
    }

    &[data-position='bottom'] {
        transform: translateY(-50%);
    }
}
