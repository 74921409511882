@use "./_mantine.scss" as *;
.content {
    padding: var(--mantine-spacing-md);
}

.listNoResults {
    display: flex;

    justify-content: center;
    align-items: center;

    padding: var(--mantine-spacing-xl);

    font-size: 12px;
    text-align: center;
}
