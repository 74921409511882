@use "./_mantine.scss" as *;
.connectionLines {
    --button-background: var(--mantine-color-gray-1);
    --button-color: var(--mantine-color-gray-6);
    --border-color: var(--mantine-color-gray-2);

    display: flex;
    align-items: center;

    gap: 4px;
}

.connectionLinesButton {
    background-color: transparent;
    border: 1px solid var(--border-color);
    color: var(--button-color);

    &[data-active='true'] {
        border-color: var(--button-background);
        background-color: var(--button-background);
        color: var(--connection-line-color);
    }
}
