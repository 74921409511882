@use "./_mantine.scss" as *;
.tips {
    pointer-events: all;

    position: relative;

    width: 320px;

    background-color: #ffffff;

    border-radius: var(--mantine-radius-xs);
    outline: 1px solid var(--mantine-color-gray-3);

    box-shadow: var(--mantine-shadow-md);

    font-size: var(--mantine-font-size-sm);
}

.tipsContent {
    padding: var(--mantine-spacing-sm);
}

.tipsFooter {
    display: flex;
    align-items: center;
    justify-content: space-between;

    gap: calc(var(--mantine-spacing-xs) / 2);

    padding: var(--mantine-spacing-sm);

    background-color: var(--mantine-color-gray-0);
}

.tipTitle {
    display: flex;
    align-items: center;

    gap: calc(var(--mantine-spacing-xs) / 2);

    font-weight: 600;

    svg {
        width: 16px;
        height: 16px;

        stroke-width: 1.5px;

        color: var(--mantine-color-primary-6);
    }
}

.tipDescription {
    margin-top: var(--mantine-spacing-xs);

    b {
        font-weight: 600;
    }
}
