@use "./_mantine.scss" as *;
.group {
    position: absolute;
    left: -2px;
    top: -2px;

    width: calc(100% + 4px);
    height: calc(100% + 4px);

    background: var(--group-bg);
    border-width: 2px;
    border-style: var(--group-border-style);
    border-color: var(--group-border-color);

    &::before {
        content: '';
        position: absolute;
        left: calc(var(--cell-padding) * -1);
        right: calc(var(--cell-padding) * -1);
        top: calc(var(--cell-padding) * -1);
        bottom: calc(var(--cell-padding) * -1);

        z-index: -1;
    }

    &[data-hovered='true'] {
        outline: 4px solid var(--mantine-color-primary-1);
    }

    [data-selected='true'] & {
        border-color: var(--mantine-color-primary-6);
        outline: none;
    }

    [data-dragging='true'] & {
        outline: none;
    }
}

.label {
    position: absolute;
    left: 0;
    bottom: 100%;

    font-size: 10px;
    font-weight: 500;
    color: var(--group-label-color, #000000);
}

.drawGroup {
    fill: none;
    stroke-width: 1.5px;
    stroke: #000000;
}

.innerGroup {
    cursor: default;
    position: absolute;

    top: var(--cell-padding);
    left: var(--cell-padding);
    right: var(--cell-padding);
    bottom: var(--cell-padding);
}
