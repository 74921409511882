@use "./_mantine.scss" as *;
.root {
    display: grid;
    grid-template-areas: 'image info price' 'image form form';
    grid-template-columns: 72px 4fr 2fr; // 72px = 60px image + 12px gap
    grid-template-rows: auto auto;
}

.image {
    grid-area: image;
}

.info {
    grid-area: info;

    gap: 0;
}

.price {
    grid-area: price;

    .quantity {
        margin: 3px;
        width: 30px;

        font-weight: 700;
        font-size: 12px;

        white-space: nowrap;
    }
}

.form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-area: form;

    column-gap: var(--mantine-spacing-sm);
    row-gap: 2px;

    margin-top: var(--mantine-spacing-xs);

    .comment {
        grid-column: span 2;
    }
}
