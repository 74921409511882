@use "./_mantine.scss" as *;
.animationWrapper {
    border: solid 2px transparent;
    margin: -2px;

    border-radius: calc(var(--mantine-radius-sm) + 2px);

    animation-duration: 2s;
}

.badge {
    --badge-height: 32px;

    pointer-events: all;

    display: flex;
    align-items: center;

    gap: var(--mantine-spacing-xs);

    height: var(--badge-height);
    padding: 0 var(--mantine-spacing-xs);

    font-size: var(--mantine-font-size-xs);
    font-weight: 500;
    text-decoration: none;
    color: inherit;

    background-color: #ffffff;
    border: 1px solid var(--mantine-color-gray-3);
    border-radius: var(--mantine-radius-sm);

    transition: background-color 0.3s ease-in-out;

    > svg {
        width: 16px;
        height: 16px;

        &[data-size='small'] {
            width: 12px;
            height: 12px;
        }
    }

    :global(.mantine-AvatarGroup-group) {
        margin-left: -2px;
        margin-right: -2px;
    }

    &[href],
    &[type='button'] {
        &:hover {
            background-color: var(--mantine-color-gray-0);
        }
    }

    &:disabled {
        border-color: var(--mantine-color-gray-1);
        background-color: var(--mantine-color-gray-1);
        color: var(--mantine-color-gray-5);

        svg {
            fill: var(--mantine-color-gray-5);
        }
    }
}

.badgeColors {
    position: relative;

    width: 24px;
    height: 12px;
}

.badgeColor {
    position: absolute;
    top: 0;

    width: 12px;
    height: 12px;

    border-radius: 50%;
    border: 1px solid #ffffff;

    background-color: var(--legend-item-color);

    &:nth-child(1) {
        left: 0;
    }

    &:nth-child(2) {
        left: 6px;
    }

    &:nth-child(3) {
        left: 12px;
    }

    &:nth-child(4) {
        left: 18px;
    }
}
