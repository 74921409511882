@use "./_mantine.scss" as *;
.list {
    display: flex;
    gap: 20px;

    padding: 0 var(--mantine-spacing-xs);

    &::before {
        content: unset;
    }
}

.tab {
    position: relative;

    display: flex;
    align-items: center;

    height: 36px;

    margin: 0;
    padding: 0;

    background: transparent !important;
    color: var(--mantine-color-gray-6);
    border: none;

    transition: color 0.3s ease;

    :global .mantine-Tabs-tabSection {
        &[data-position='left'] {
            margin-right: 6px;
        }

        &[data-position='right'] {
            margin-left: 6px;
        }
    }

    &:hover,
    &:focus,
    &[data-active] {
        color: var(--mantine-color-gray-8);
        outline: none;
    }

    &:hover {
        outline: none;

        text-decoration: underline;
        text-decoration-color: var(--mantine-color-gray-2);
        text-underline-offset: 3px;
    }
}

.overflowTabs {
    :global .mantine-Menu-item {
        padding: 0;
        background-color: transparent;
    }

    .tab {
        height: auto;
    }

    .tab,
    .link {
        width: 100%;
        padding: var(--mantine-spacing-xs);
    }
}

.link {
    display: inline-block;

    margin-top: auto;
    margin-bottom: auto;

    font-size: var(--mantine-font-size-sm);

    color: var(--mantine-color-gray-6) !important;

    text-decoration: none;

    &:hover,
    &:focus {
        color: var(--mantine-color-gray-8) !important;
        outline: none;

        text-decoration: underline;
        text-decoration-color: var(--mantine-color-gray-2);
        text-underline-offset: 3px;
    }
}

.section {
    &:empty {
        display: none;
    }
}

.section + .section {
    padding-top: 0 !important;
}

.header {
    background-color: white;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: var(--mantine-z-index-max);
    padding: var(--mantine-spacing-xs) var(--mantine-spacing-xs);

    border-bottom: 1px solid var(--mantine-color-gray-1);
}

.simpleHeader {
    background-color: white;
    position: sticky;

    top: 0;

    z-index: var(--mantine-z-index-max);
    padding: var(--mantine-spacing-xs);

    font-size: 14px;
    font-weight: 600;

    border-bottom: 1px solid var(--mantine-color-gray-1);
}
