@use "./_mantine.scss" as *;
.legend {
    position: relative;

    display: grid;
    gap: var(--mantine-spacing-md);

    z-index: 98;

    min-width: 200px;
    padding: var(--mantine-spacing-md);

    font-size: 12px;

    background-color: #ffffff;
    border: 1px solid var(--mantine-color-gray-2);
    border-radius: var(--mantine-radius-sm);
    box-shadow: var(--mantine-shadow-sm);
}

.close {
    position: absolute;
    top: 6px;
    right: 6px;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 20px;
    height: 20px;

    svg {
        width: 14px;
        height: 14px;
    }
}

.items {
    display: grid;
    gap: calc(var(--mantine-spacing-xs) / 2);
}

.title {
    padding-bottom: calc(var(--mantine-spacing-xs) / 2);

    font-weight: 600;
    line-height: 1;
}

.item {
    display: flex;
    align-items: center;
    gap: calc(var(--mantine-spacing-xs) / 1);

    line-height: 1;
}
