@use "./_mantine.scss" as *;
.accordion {
    :global {
        .mantine-Accordion-item {
            border-bottom: none;
        }

        .mantine-Accordion-control {
            padding: 0;
        }

        .mantine-Accordion-chevron {
            margin: 8px;
        }

        .mantine-Accordion-label {
            padding: var(--mantine-spacing-xs);
            padding-left: 0;
        }

        .mantine-Accordion-content {
            padding: 0;
        }
    }
}
