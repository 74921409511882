@use "./_mantine.scss" as *;
.nav {
    width: var(--diagram-sidebar-nav-width);

    flex: 0 0 var(--diagram-sidebar-nav-width);

    align-items: center;
    justify-content: space-between;

    padding: var(--mantine-spacing-sm) 4px;
    border-left: 1px solid var(--mantine-color-gray-2);

    background-color: var(--mantine-color-gray-1);
}

.item {
    overflow: visible;

    &[data-active='true'] {
        --ai-bg: var(--mantine-color-gray-9) !important;
        --ai-color: #ffffff !important;

        --ai-hover: var(--mantine-color-gray-9) !important;
        --ai-hover-color: #ffffff !important;
    }
}
