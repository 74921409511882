@use "./_mantine.scss" as *;
.root {
    :global {
        .react-resizable-handle {
            &::after {
                right: auto;
                bottom: auto;
                left: 50%;
                top: 50%;

                transform: translate(-50%, -50%);
            }
        }

        // separate the handles
        .react-resizable-handle-se {
            transform: translateY(50%) translateX(50%);
        }

        .react-resizable-handle-sw {
            transform: translateY(50%) translateX(-50%);
        }

        .react-resizable-handle-ne {
            transform: translateY(-50%) translateX(50%);
        }

        .react-resizable-handle-nw {
            transform: translateY(-50%) translateX(-50%);
        }

        .react-resizable-handle-w {
            transform: translateX(-50%);
        }

        .react-resizable-handle-e {
            transform: translateX(50%);
        }

        .react-resizable-handle-n {
            transform: translateY(-50%);
        }

        .react-resizable-handle-s {
            transform: translateY(50%);
        }
    }
}
