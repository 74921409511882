@use "./_mantine.scss" as *;
.chat {
    flex-grow: 1;
    height: 100%;
}

.close {
    margin: var(--mantine-spacing-xs);
    margin-bottom: 0;

    align-self: flex-end;

    @media (min-width: $mantine-breakpoint-sm) {
        display: none;
    }
}
