@use "./_mantine.scss" as *;
.root {
    cursor: pointer;

    background-color: var(--mantine-color-white);
    border: 1px solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
    padding: 6px;
    border-radius: var(--mantine-radius-sm);
    transition:
        background-color 100ms ease,
        border-color 100ms ease;

    &[data-checked] {
        background-color: var(--mantine-color-primary-light);
        border-color: var(--mantine-color-primary-light);
    }

    & * {
        pointer-events: none;
        user-select: none;
    }

    &[data-interactive] {
        cursor: initial;

        & * {
            pointer-events: all;
            user-select: all;
        }
    }

    :global {
        .mantine-Checkbox-label,
        .mantine-Checkbox-description {
            padding-left: 8px;
        }
    }

    &[data-border-top='false'] {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-top-width: 0;
    }

    &[data-border-bottom='false'] {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-width: 0;
    }
}
