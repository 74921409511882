@use "./_mantine.scss" as *;
.target {
    display: flex;

    justify-content: center;
    align-items: center;

    width: var(--target-width);
    height: var(--target-width);

    &:focus {
        outline: none;
    }

    svg {
        width: var(--target-icon-width);
        height: var(--target-icon-width);
    }
}

.dropdown {
    min-width: 240px;
}

.itemSection {
    width: 16px;
}

.item {
    outline: none !important;

    strong {
        font-weight: 600;
    }

    svg {
        width: 100%;
        height: auto;

        stroke-width: 1.5px;

        &[data-disabled] {
            color: var(--mantine-color-gray-4);
        }

        &[data-green] {
            color: var(--mantine-color-green-5);
        }
    }
}
