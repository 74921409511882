@use "./_mantine.scss" as *;
.teaserGroup {
    border: 1px solid var(--mantine-color-gray-2);
    border-radius: var(--mantine-radius-xs);

    .teaser {
        border: 0;
        border-radius: 0;
    }

    .teaser + .teaser {
        border-top: 1px solid var(--mantine-color-gray-2);
    }
}

.teaser,
.error {
    gap: var(--mantine-spacing-xs);
    padding: var(--mantine-spacing-sm);

    border: 1px solid var(--mantine-color-gray-2);
    border-radius: var(--mantine-radius-xs);

    &[data-compact] {
        padding: var(--mantine-spacing-xs);
    }

    &[data-panel='true'] {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.teaser {
    display: flex;

    justify-content: space-between;
    align-items: center;

    &:hover {
        background-color: var(--mantine-color-gray-0);
        cursor: pointer;
    }
}

.teaserContent,
.errorContent {
    flex-grow: 1;
    flex-shrink: 1;
}

.teaserTitle,
.errorTitle {
    font-size: 13px;
    font-weight: 600;

    text-wrap: balance;

    [data-compact] & {
        font-size: 12px;
        font-weight: 500;
    }
}

.teaserDescription,
.errorDescription {
    margin-top: calc(var(--mantine-spacing-xs) / 2);
    font-size: 11px;
}

.errorActions {
    display: flex;
    justify-content: space-between;

    margin-top: var(--mantine-spacing-xs);

    button {
        --button-height: 24px !important;
        --button-padding-x: 8px !important;

        color: var(--mantine-color-gray-7);

        svg {
            width: 12px;
            height: 12px;

            margin-right: 8px;
        }
    }
}

.errorFeatures {
    display: flex;
    gap: calc(var(--mantine-spacing-xs) / 2);
}

.errorFeature {
    display: flex;
    align-items: center;

    height: 24px;
    padding: 0 8px;

    font-size: 11px;
    font-weight: 600;

    border-radius: var(--mantine-radius-sm);

    &[data-color='blue'] {
        background-color: var(--mantine-color-blue-0);
        color: var(--mantine-color-blue-7);

        border: 1px solid var(--mantine-color-blue-1);
    }

    &[data-color='green'] {
        background-color: var(--mantine-color-green-0);
        color: var(--mantine-color-green-7);

        border: 1px solid var(--mantine-color-green-1);
    }
}

.teaserBadge {
    display: inline-flex;
    align-items: center;

    padding: 0 6px;
    height: 20px;

    font-size: 10px;
    font-weight: 600;

    background-color: var(--mantine-color-gray-2);
    color: var(--mantine-color-gray-7);

    border-radius: var(--mantine-radius-xs);

    &[data-type='current-os'] {
        background-color: var(--mantine-color-green-6);
        color: #ffffff;
    }

    &[data-type='warning'] {
        background-color: var(--mantine-color-orange-6);
        color: var(--mantine-color-gray-7);
        color: #ffffff;
    }

    &[data-type='error'] {
        background-color: var(--mantine-color-red-6);
        color: #ffffff;
    }

    &[data-compact] {
        height: 16px;

        padding: 0 4px;
    }
}

.panel {
    margin-top: -1px;
    padding: var(--mantine-spacing-xs);

    border: 1px solid var(--mantine-color-gray-2);
    border-radius: var(--mantine-radius-xs);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.quickfixComponentInstanceConfiguration {
    > div {
        padding: 0 !important;
    }

    > div + div {
        margin-top: var(--mantine-spacing-sm);
    }

    > div:last-child {
        margin-bottom: var(--mantine-spacing-xs);
    }

    [data-specification-row='power-flow'] {
        display: none;
    }
}

.componentInstanceSelectorItem {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    aspect-ratio: 1;

    font-size: var(--mantine-font-size-xs);
    font-weight: 500;

    border: 1px solid var(--mantine-color-gray-2);
    border-radius: var(--mantine-radius-sm);

    svg {
        width: 50%;
    }

    &:hover {
        background-color: var(--mantine-color-gray-0);
    }

    &[data-selected='true'] {
        background-color: var(--mantine-color-gray-1);
    }
}
