@use "./_mantine.scss" as *;
.element {
    position: absolute !important;

    left: var(--element-left);
    top: var(--element-top);

    width: var(--element-width);
    height: var(--element-height);

    pointer-events: none !important;

    &[data-editable='true'] {
        pointer-events: all !important;
    }

    &[data-selectable-preview='true'],
    &[data-selected='true'] {
        outline: 2px solid var(--mantine-color-primary-6);
    }

    &[data-selectable-type='group'] {
        // make sure our groups are behind almost all of the other elements.
        z-index: 80 !important;
    }

    &[data-pasting='true'] {
        pointer-events: none !important;
    }

    :global {
        .react-resizable-handle {
            &::after {
                right: auto;
                bottom: auto;
                left: 50%;
                top: 50%;

                transform: translate(-50%, -50%);
            }
        }

        .react-resizable-handle-se {
            transform: translateY(calc(50% + 1px)) translateX(calc(50% + 1px)); // 1px because we use outline
        }

        .react-resizable-handle-sw {
            transform: translateY(calc(50% + 1px)) translateX(-50%);
        }

        .react-resizable-handle-ne {
            transform: translateY(calc(-50% - 1px)) translateX(calc(50% + 1px));
        }

        .react-resizable-handle-nw {
            transform: translateY(calc(-50% - 1px)) translateX(calc(-50% - 1px));
        }

        .react-resizable-handle-w {
            transform: translateX(calc(-50% - 1px));
        }

        .react-resizable-handle-e {
            transform: translateX(calc(50% + 1px));
        }

        .react-resizable-handle-n {
            transform: translateY(calc(-50% - 1px));
        }

        .react-resizable-handle-s {
            transform: translateY(calc(50% + 1px));
        }
    }
}
