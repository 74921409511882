@use "./_mantine.scss" as *;
@keyframes pulse {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.markdown {
    h1,
    h2,
    h3,
    h4 {
        font-size: 13px;
        font-weight: normal;
    }

    ul,
    ol {
        padding-left: var(--mantine-spacing-lg);
    }

    a {
        color: var(--mantine-color-primary-7);
    }

    strong {
        font-weight: 600;

        // hack for streaming footnotes eg. [?]
        em {
            font-style: normal;

            opacity: 0.5;
        }
    }

    em {
        // hack for inline loader
        strong {
            overflow: hidden;

            display: inline-block;
            width: 4px;
            height: 14px;

            vertical-align: middle;
            margin-bottom: 2px;

            background-color: var(--mantine-color-gray-4);

            color: transparent;

            animation: pulse 500ms infinite alternate-reverse;
        }
    }

    blockquote {
        padding: 4px var(--mantine-spacing-xs);
        background-color: var(--mantine-color-gray-1);
        border-left: 2px solid var(--mantine-color-gray-3);
    }

    // highlight footnotes
    :target {
        background-color: var(--mantine-color-primary-1);
    }

    [data-footnotes] {
        padding: var(--mantine-spacing-xs) 0;
        background-color: var(--mantine-color-gray-0);
        border-left: 2px solid var(--mantine-color-gray-1);

        font-size: var(--mantine-font-size-xs);

        h2 {
            display: none;
        }
    }

    * {
        margin: 0;
    }

    > * + * {
        margin-top: var(--mantine-spacing-xs);
    }

    sup {
        vertical-align: inherit;
        font-size: inherit;
        font-weight: 600;

        a {
            text-decoration: none;
        }
    }

    [data-footnote-ref] {
        // add brackets around inline references
        &::before {
            content: '[';
        }

        &::after {
            content: ']';
        }
    }
}

.resources {
    padding: 4px var(--mantine-spacing-xs);
    background-color: var(--mantine-color-gray-0);
    border-left: 2px solid var(--mantine-color-gray-2);

    ul,
    ol {
        padding-left: var(--mantine-spacing-lg);
    }
}
