@use "./_mantine.scss" as *;
.section {
    overflow: hidden;

    position: absolute;
    left: 0;
    right: var(--diagram-sidebar-nav-width);
    bottom: 0;

    z-index: 1;

    background: #ffffff;
}

.noResults {
    font-size: 12px;
}
