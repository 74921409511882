@use "./_mantine.scss" as *;
.controls {
    --carousel-control-size: 20px;

    padding: 4px;
}

.control {
    color: var(--mantine-color-brand-5);
    border: none;

    box-shadow: 0 0 10px 2px rgba(#000, 0.1);

    transition: all 200ms;

    &:hover {
        background-color: var(--mantine-color-brand-5);
        color: white;
    }

    &[data-inactive='true'] {
        visibility: hidden;
    }
}
