@use "./_mantine.scss" as *;
.wireSizingWrapper {
    width: calc(100% + var(--mantine-spacing-xs) * 2);
    margin-left: calc(var(--mantine-spacing-xs) * -1);
}

.wireSizing {
    --grid-spacing: 6px;
    --grid-small-column: 120px;
    --grid-large-column: 160px;

    // min-width: 1200px;
}

.row {
    display: grid;
    grid-template-columns:
        var(--grid-large-column)
        var(--grid-small-column)
        var(--grid-small-column)
        var(--grid-small-column);
    gap: var(--grid-spacing);

    align-items: center;
}

.gridHeader {
    margin-bottom: var(--grid-spacing);
    padding: 0 var(--mantine-spacing-xs);

    font-weight: 500;
    text-align: center;
}

.grid {
    padding: var(--grid-spacing) var(--mantine-spacing-xs);

    .wireSizing form:nth-child(even) & {
        background-color: var(--mantine-color-gray-0);
    }
}

.gridConnectionFields {
    display: grid;
    grid-template-columns: var(--grid-small-column) var(--grid-small-column) var(--grid-small-column) 2fr 2fr;
    gap: var(--grid-spacing);
}

.gridConnectionValue {
    display: flex;

    justify-content: center;
    align-items: center;

    &[data-error='true'] {
        font-weight: 500;
        color: var(--mantine-color-red-7);
    }
}

.connectionInformationLine {
    display: grid;
    grid-template-columns: 100px 120px;

    font-size: 12px;

    > *:last-child {
        font-weight: 500;
    }
}

.resultApplier {
    display: flex;
    align-items: center;

    width: 100%;

    > div:first-child {
        flex-grow: 1;
        flex-shrink: 1;
    }

    input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.resultApplierButton {
    flex-grow: 0;
    flex-shrink: 0;

    display: flex;

    justify-content: center;
    align-items: center;

    width: 30px;
    height: 30px;

    background-color: #ffffff;

    border: 1px solid var(--mantine-color-gray-2);
    border-left: 0;

    border-top-right-radius: var(--mantine-radius-xs);
    border-bottom-right-radius: var(--mantine-radius-xs);

    &[data-applied] {
        color: var(--mantine-color-green-7);
    }
}

.maximumVoltageDrop {
    display: flex;
    align-items: center;

    gap: 6px;

    // width: 160px;

    font-size: 12px;
    font-weight: 500;

    --input-height: 26px;

    > *:first-child {
        flex-grow: 0;
        flex-shrink: 0;
    }

    :global .mantine-NumberInput-controls {
        --input-height: 24px;
    }
}
