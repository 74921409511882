@use "./_mantine.scss" as *;
.root {
    position: relative;

    border: 1px solid var(--mantine-color-gray-2);
    border-radius: var(--mantine-radius-sm);

    &::after {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(black, 0.02);
    }
}
