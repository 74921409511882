@use "./_mantine.scss" as *;
.list {
    --tabs-list-border-size: 1px;
    --tab-border-color: var(--mantine-color-gray-2);
    --tab-hover-color: transparent;

    margin-bottom: var(--mantine-spacing-md);

    flex-wrap: nowrap;

    &::before {
        border-top: none;
    }
}

.tab {
    --tabs-color: var(--mantine-color-brand-4);

    flex-shrink: 0;

    border-bottom-width: 3px;

    @media (max-width: $mantine-breakpoint-sm) {
        padding: var(--mantine-spacing-xs) var(--mantine-spacing-xs);
    }
}

.panel {
    opacity: 0;
    display: none;

    &[data-active='true'] {
        opacity: 1;
        display: block;
    }

    transition: opacity 0.5s ease-in-out;
}

@starting-style {
    .panel[data-active='true'] {
        opacity: 0;
    }
}

.sticky {
    background-color: rgba(255, 255, 255, 0.9);
    border-bottom: 1px solid var(--mantine-color-gray-1);
    border-left: 1px solid var(--mantine-color-gray-1);

    backdrop-filter: blur(5px);

    :global .mantine-Tabs-list {
        align-items: center;
        justify-content: center;

        margin-bottom: 0;
    }

    :global .mantine-Tabs-tab {
        border-width: 0;
        border-bottom-width: 2px;
    }
}
