@use "./_mantine.scss" as *;
.anchor {
    fill: transparent;

    --color: var(--mantine-color-primary-6);
    --color-error: var(--mantine-color-red-6);
    --color-background: white;

    pointer-events: all;
    cursor: pointer;

    &[data-voltagetype='AC'] {
        --color: var(--mantine-color-AC-6);
    }

    &[data-voltagetype='DC'] {
        --color: var(--mantine-color-DC-6);
    }

    &[data-suggested='true'] {
        stroke: var(--color);
        fill: var(--color-background);
    }

    &[data-connected='true'] {
        fill: transparent;
    }

    &[data-forbidden='true'] {
        --color: var(--color-error);
        stroke: var(--color);
        fill: var(--color-background);
    }

    &[data-highlighted='true'] {
        fill: var(--color);
        stroke: var(--color);
    }

    &[data-state='connecting'] {
        fill: var(--color);
        stroke: var(--color);
    }

    [data-state='dragging'] & {
        opacity: 0;
    }
}

.activeArea {
    pointer-events: all;
    cursor: pointer;

    fill: transparent;
}
