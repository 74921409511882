@use "./_mantine.scss" as *;
.action {
    display: flex;

    align-items: center;
    justify-content: center;

    width: 20px;
    height: 20px;

    &:focus {
        outline: none;
    }

    svg {
        width: 20px;
        height: 20px;

        stroke-width: 1.5px;

        &[data-resolved] {
            color: var(--mantine-color-green-6);
        }

        &[data-size-18] {
            width: 18px;
            height: 18px;
        }

        &[data-size-16] {
            width: 16px;
            height: 16px;
        }
    }

    &[disabled] {
        opacity: 0.25;
    }

    &[data-compact] svg {
        width: 14px;
        height: 14px;

        stroke-width: 2px;
    }
}

.badge {
    display: flex;
    align-items: center;

    gap: 4px;

    height: 20px;
    padding: 0 5px;

    font-size: 10px;
    font-weight: 600;

    background-color: var(--mantine-color-gray-0);
    border: 1px solid var(--mantine-color-gray-1);
    border-radius: var(--mantine-radius-sm);

    svg {
        width: 12px;
        height: 12px;

        transform: rotate(45deg);
    }
}

.divider {
    margin: var(--mantine-spacing-xs) 0;

    [data-comment='new'] & {
        height: var(--mantine-spacing-xs);
        margin: 0;
        border-top: none;
    }
}
